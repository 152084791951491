import * as AccountService from "../../lib/services/Account.Service";
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
    let navigate = useNavigate();

    const submit = (event) => {
        event.preventDefault();
        const form = document.getElementById('signIn__form').elements;
        const formData = {
            email: form.email.value,
            password: form.password.value
        }

        if( formData.email && formData.password ) {
            if( AccountService.signIn(formData) ) {
                navigate('/admin');
            }
        }

        console.log('OnSubmit', formData);
    }

    return (
        <div className="container" style={{paddingTop: 150}}>
            <div className="row">
                <div className="col-4 offset-4">
                    <h2 align="center" className="display-4">My Admin</h2>

                    <div className="card" style={{marginTop: 50}}>
                        <div className="card-body">
                            <form id="signIn__form" onSubmit={e => submit(e)}>
                                <fieldset>
                                    <div className="mb-3">
                                        <input type="email" name="email" className="form-control" placeholder="Email@" maxLength={150} required />
                                    </div>
                                    <div className="mb-3">
                                        <input type="password" name="password" className="form-control" placeholder="Password" minLength={8} maxLength={16} required />
                                    </div>
                                    <div className="mb-3" style={{textAlign:'right'}}>
                                        <button type="submit" className="btn btn-primary">Ingresar</button>
                                    </div>
                                </fieldset>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn;