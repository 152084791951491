import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as CustomerService from '../../lib/services/Customer.Service';

const New = () => {
    let navigate = useNavigate();

    const submit = async (e) => {
        e.preventDefault();
        const form = document.getElementById('customer__new_form').elements;
        const formData = {
            user: {
                name: form.name.value,
                lastname: form.lastname.value,
                email: form.email.value,
                phone: form.phone.value,
                tags: form.tags.value,
                notes: form.notes.value,
                rol: 'customer',
                status: 'active',
                createdAt: new Date(),
                updatedAt: new Date()
            },
            company: {
                address: form.address.value ? form.address.value : '',
                address2: form.address2.value ? form.address2.value : '',
                state_id: form.state_id.value ? form.state_id.value : '',
                province_id: form.province_id.value ? form.province_id.value : '',
                zip: form.zip.value ? form.zip.value : '',
                rfc: form.rfc.value ? form.rfc.value.toUpperCase() : '',
                name: form.company.value ? form.company.value.toUpperCase() : ''
            }
        }
        
        if(formData) {
            console.log('Submits:',formData);
            let result = await CustomerService.create(formData);
            if( result ) {
                toast.success(`Usuario ${formData.user.name + ' ' + formData.user.lastname} creado correctamente`);
                navigate('/admin/customers');
            }
        }
    }

    return <div>
        <div className="row">
            <div className="col-lg-6 offset-lg-3">
                <div className="card" style={{marginTop: 100}}>
                    <div className="card-header bg-dark text-white">
                        Nuevo Cliente
                    </div>
                    <div className="card-body">
                        <form id="customer__new_form" onSubmit={e => submit(e)}>
                            <legend>Información Básica *</legend>
                            <fieldset>
                                <div className="mb-3">
                                    <input type="text" name="name" className="form-control" placeholder="Nombre" required />
                                </div>
                                <div className="mb-3">
                                    <input type="text" name="lastname" className="form-control" placeholder="Apellidos" required />
                                </div>
                                <div className="mb-3">
                                    <input type="email" name="email" className="form-control" placeholder="Email" required />
                                </div>
                                <div className="mb-3">
                                    <input type="digits" name="phone" className="form-control" placeholder="Teléfono" minLength={10} maxLength={10} required />
                                </div>
                            </fieldset>

                            <legend>Dirección Cliente/Empresa</legend>
                            <fieldset>
                                <div className="mb-3">
                                    <input type="text" name="address" className="form-control" placeholder="Dirección" minLength={1} maxLength={150} />
                                </div>
                                <div className="mb-3">
                                    <input type="text" name="address2" className="form-control" placeholder="Dirección 2" minLength={1} maxLength={150} />
                                </div>
                                <div className="mb-3">
                                    <select name="state_id" className="form-control">
                                        <option value="">Selecciona un Estado</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <select name="province_id" className="form-control">
                                        <option value="">Selecciona un Municipio</option>
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <input type="digits" name="zip" className="form-control" placeholder="Código Postal" minLength={5} maxLength={5} />
                                </div>
                            </fieldset>

                            <legend>Información Fiscal</legend>
                            <fieldset>
                                <div className="mb-3">
                                    <input type="text" name="rfc" className="form-control" placeholder="RFC" minLength={13} maxLength={13} />
                                </div>
                                <div className="mb-3">
                                    <input type="text" name="company" className="form-control" placeholder="Razón Social" minLength={1} maxLength={150} />
                                </div>
                            </fieldset>

                            <legend>Información Adicional</legend>
                            <fieldset>
                                <div className="mb-3">
                                    <textarea name="tags" className="form-control" placeholder="Etiquetas (ej. Cliente Premium, Cliente Oro, Cliente Potencial...) separados por comas" />
                                </div>
                                <div className="mb-3">
                                    <textarea name="notes" className="form-control" placeholder="Notas o Comentarios" />
                                </div>
                            </fieldset>

                            <div className="mb-3">
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-outline-primary">Crear Cliente</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default New;