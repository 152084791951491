import Header from "../core/templates/header";
import {
    Outlet
  } from "react-router-dom";


const MainModule = () => {
    return <div>
        <Header />
        <section id="main__body">
        <Outlet />
        </section>
    </div>
};

export default MainModule;