import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Users = () => {
    const users = [
        {
            id: 1,
            name: "Eduardo Vallecillo Cervantes",
            email: "eduardov@softwhere.com.mx",
            title: "Developer",
            rol: 'admin',
            phone: '5555555555',
            dateRegister: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        }
    ];

    return (
        <div className="container body__content">
            <h1 className="display-4">
                Usuarios

                <Link to="new" className="text-primary">
                    <FontAwesomeIcon icon={faPlusCircle} className="icon__create" />
                </Link>
            </h1>

            { /** Show customers table */}
            <section style={{marginTop: 30}}>
                { !users || !users.length ? <p align="center">No hay información para mostrar</p> : (
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Usuario</th>
                                <th>Email</th>
                                <th>Teléfono</th>
                                <th>Rol</th>
                                <th>Titulo</th>
                                <th>F. Ingreso</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users.map(u => {
                                    return (
                                        <tr key={u.id}>
                                            <td>{u.name}</td>
                                            <td>{u.email}</td>
                                            <td>{u.phone}</td>
                                            <td>
                                                <span className="badge bg-warning">{u.rol}</span>
                                            </td>
                                            <td>
                                                <strong>{u.title}</strong>
                                            </td>
                                            <td>{u.dateRegister}h</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                ) }
            </section>
        </div>
    );
}

export default Users;