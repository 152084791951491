import { faPlusCircle, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import * as CustomerService from '../../lib/services/Customer.Service';

const Main = () => {
    const [customers, setCustomers] = useState(0);

    useEffect(() => {
        const loadData = async () => {
            let result = await CustomerService.read();
            console.log('Customers:', result);
            setCustomers( result.map(c => { 
                    let customer = c;
                    if(c.address === undefined && c.address2 === undefined) { 
                        customer.address = ''; 
                        customer.address2 = '';
                    } 
                    
                    return customer; 
                })
            );
        }

        if(!customers) {
            loadData();
        }
    }, [customers]);

    return <div className="container body__content">
        <h1 className="display-4">
            Clientes
            <Link to="new" className="text-primary">
                <FontAwesomeIcon icon={faPlusCircle} className="icon__create" />
            </Link>
        </h1>

        { /** Show customers table */}
        <section style={{marginTop: 30}}>
            { !customers || !customers.length ? <p align="center">No hay clientes creados</p> : (
                <div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Email</th>
                                <th>Teléfono</th>
                                <th>Dirección</th>
                                <th>Ordenes</th>
                                <th>Facturas</th>
                                <th>F. Reg.</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { customers.map( c => {
                                return (
                                    <tr key={c.id}>
                                        <td>{c.name + ' ' + c.lastname}</td>
                                        <td>{c.email}</td>
                                        <td>{c.phone}</td>
                                        <td>{c.address + ' ' + c.address2}</td>
                                        <td>{0}</td>
                                        <td>{0}</td>
                                        <td>{new Date(c.createdAt.split('T')[0]).toLocaleDateString() } { c.createdAt.split('T')[1].split('.')[0]}h</td>
                                        <td>
                                            <Link to={`/admin/customers/${c.id}/edit`}>
                                                <FontAwesomeIcon icon={faUserEdit} size="sm" />
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            ) }
        </section>
    </div>
}

export default Main;