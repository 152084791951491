import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuthData, removeData } from '../../lib/services/Account.Service';

const Header = () => {
    const [userData, setUserData] = useState(0);
    let navigate = useNavigate();

    useEffect(() => {
        if(!userData) {
            let result = getAuthData();
            if( result ){
                setUserData(result);
            }
        }
    }, [userData]);

    const logOut = (e) => {
        e.preventDefault();
        removeData();
        navigate('/');
    }

    return (
        <div className="bg-dark text-white" style={{height: 45, padding: 10, textAlign:"center"}}>
            <div className="row">
                <div className="col-2">
                    MyAdmin
                </div>
                <div className="col-7">
                    <ul id="menu__main" className="nav justify-content-center">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/admin">Dashboard</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/admin/orders">Ordenes</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/admin/invoices">Facturas</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/admin/cashflow">Caja</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/admin/customers">Clientes</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/admin/users">Usuarios</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-2" style={{textAlign:'right'}}>
                    <Link to="/admin/profile" className="text-info" style={{textDecoration: "none"}}>{ userData.name }</Link>
                </div>
                <div className="col-1" style={{textAlign:'left'}}>
                    <a href="/" className="text-info" onClick={e => logOut(e)}>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Header;