export const setAuthData = (data: any) => {
    localStorage.setItem('session', JSON.stringify(data));
}

export const getAuthData = () => {
    let data = localStorage.getItem('session');
    return data ? JSON.parse(data) : null;
}

export const signIn = (data: {email: string, password: string}) => {
    //Suppose to verify
    const profile = {
        email: data.email,
        name: "Your Name",
        rol: 'admin',
        lastLogin: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
    }

    setAuthData(profile);
    return profile;
}

export const removeData = () => {
    localStorage.clear();
}