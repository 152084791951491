import { getAuthData } from "../../lib/services/Account.Service";

const Profile = () => {
    const userData = getAuthData();

    return <div className="container">

        <div className="row">
            <div className="col-lg-6 offset-lg-3">
                <div className="card" style={{marginTop: 100}}>
                    <div className="card-header bg-dark text-white">
                        Actualizar Perfil
                    </div>
                    <div className="card-body">
                        <form>
                            <legend>Información de Cuenta</legend>
                            <fieldset>
                                <div className="mb-3">
                                    <input type="text" name="name" className="form-control" placeholder="Nombre" defaultValue={userData.name} />
                                </div>
                                <div className="mb-3">
                                    <input type="email" name="email" className="form-control" placeholder="Email" defaultValue={userData.email} />
                                </div>
                                <div className="mb-3">
                                    <input type="digits" name="phone" className="form-control" placeholder="Teléfono" defaultValue={userData.phone} />
                                </div>
                                <div className="mb-3">
                                    <input type="text" name="rol" className="form-control" placeholder="Rol" defaultValue={userData.rol} readOnly disabled />
                                </div>                                
                            </fieldset>

                            <legend>Contraseña</legend>
                            <fieldset>
                                <div className="mb-3">
                                    <input type="password" name="password" className="form-control" placeholder="Contraseña Actual" minLength={8} maxLength={16} />
                                </div>
                                <div className="mb-3">
                                    <input type="password" name="new_password" className="form-control" placeholder="Nueva Contraseña" minLength={8} maxLength={16} />
                                </div>
                            </fieldset>

                            <div className="mb-3">
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-outline-primary">Actualizar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
    </div>;
}

export default Profile;