import { faArrowDown, faArrowUp, faEdit, faFileArchive, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const CashFlow = () => {
    const transactions = [
        {
            id: 1,
            name: "Pago de Servicios Luz",
            provider: {
                name: "CFE"
            },
            type: 'outcome',
            amount: 250.70,
            source: 'Cta ****75 Santander',
            date: new Date().toLocaleDateString(),
            registeredBy: 'Eduardo Vallecillo Cervantes',
            invoice: true,
            invoice_src: '',
        },
        {
            id: 2,
            name: "Pago de Servicios Internet",
            provider: {
                name: "TotalPlay"
            },
            type: 'outcome',
            amount: 499.00,
            source: 'Cta ****75 Santander',
            date: new Date().toLocaleDateString(),
            registeredBy: 'Eduardo Vallecillo Cervantes',
            invoice: true,
            invoice_src: '',
        },
        {
            id: 3,
            name: "Factura XX1907",
            type: 'income',
            customer: {
                name: "Federico Silva"
            },
            amount: 1560.83,
            source: 'Cta ****83 Bancomer',
            date: new Date().toLocaleDateString(),
            registeredBy: 'Eduardo Vallecillo Cervantes',
            invoice: true,
            invoice_src: '',
        }
    ];

    const calculateBalance = () => {
        let balance = 0;
        transactions.map( t => {
            if(t.type === 'income') {
                balance = balance + t.amount;
            } else {
                balance = balance - t.amount;
            }

            return t;
        });
        
        if(balance) {
            return <span className="text-success">${balance.toFixed(2)}</span>
        } else{
            return <span className="text-danger">${balance.toFixed(2)}</span>
        }

    }

    return (
        <div className="container body__content">
            <div className="row">
                <div className="col-6">
                    <h1 className="display-4">
                        Flujo de Caja
                        
                        <Link to="new" className="text-primary">
                            <FontAwesomeIcon icon={faPlusCircle} className="icon__create" />
                        </Link>
                    </h1>
                </div>
                <div className="col-3" style={{paddingTop:28, textAlign:'right'}}>
                    <h4>Balance: {calculateBalance()}</h4>
                </div>
                <div className="col-3" style={{paddingTop:25}}>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="mb-3" style={{paddingTop: 7}}>
                                <label>Filtrar: </label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="mb-3">
                                <select className="form-control" name="filter">
                                    <option value="month">Mes</option>
                                    <option value="year">Año</option>
                                </select>
                            </div>
                            
                        </div>
                    </div>
                    
                    
                </div>
            </div>
            

            { /** Show customers table */ }
            <section style={{marginTop: 30}}>
                { !transactions || !transactions.length ? <p align="center">No hay información para mostrar</p> : (
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Concepto</th>
                                <th>Cliente/Proveedor</th>
                                <th>Cantidad</th>
                                <th>Factura</th>
                                <th>Recurso</th>
                                <th>Fecha</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {  
                                transactions.map( t => {
                                    return (
                                        <tr key={t.id}>
                                            <td>
                                                {t.type === 'income' ? <FontAwesomeIcon icon={faArrowUp} size="sm" className="text-success" /> : <FontAwesomeIcon icon={faArrowDown} size="sm" className="text-danger" /> }
                                            </td>
                                            <td>{t.name}</td>
                                            <td>
                                                <span>{t.customer && t.customer.name ? t.customer.name : t.provider.name }</span>
                                            </td>
                                            <td>${t.amount}</td>
                                            <td>
                                                { !t.invoice ? 'No' : (
                                                    <a href="/" className="text-danger" onClick={ e => { e.preventDefault(); }}>
                                                        <FontAwesomeIcon icon={faFileArchive} size="md" />
                                                    </a>
                                                )}
                                            </td>
                                            <td>{t.source}</td>
                                            <td>{t.date}</td>
                                            <td>
                                                <a href="/" onClick={ e => { e.preventDefault(); }}>
                                                    <FontAwesomeIcon icon={faEdit} size="md" />
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                )}
            </section>
        </div>
    );
}

export default CashFlow;