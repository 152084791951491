const { v4: uuidv4 } = require('uuid');

export const create = async (data: any) => {
    const id: string = uuidv4();
    // await function
    if( data ) {
        // Get all current customers
        let customers: Array<any> = await read();
        data = { id, ...data.user, company: {user_id: id, ...data.company} };
        customers.push(data);

        //Update customers
        localStorage.setItem('customers', JSON.stringify(customers));

        return data;
    }

    return;
}

export const read = async (id?: any) => {
    let customers: any = localStorage.getItem('customers');
    customers = customers ? JSON.parse(customers) : [];

    if(id) {
        return customers.find( (c: any) => c.id === id);
    } else {
        return customers;
    }
}

export const update = async (id: any, data: any) => {
    if( id && data ) {
        // Get all current customers
        let customers: Array<any> = await read();
        let customer = customers ? customers.find(c => c.id === id) : null;

        if(customer) {
            data = { ...customer, ...data.user, company: {user_id: id, ...data.company} };
            //Pull other one
            let _customers = customers.filter( c => c.id !== id);
            _customers.push(data);

            //Update customers
            localStorage.setItem('customers', JSON.stringify(_customers));

            return data;
        }
        
    }

    return;
}

export const del = async (id: any) => {
    if( id ) {
        // Get all current customers
        let customers: Array<any> = await read();
        let customer = customers ? customers.find(c => c.id === id) : null;

        if(customer) {
            //Pull other one
            let _customers = customers.filter( c => c.id !== id );

            //Update customers
            localStorage.setItem('customers', JSON.stringify(_customers));

            return true;
        }
        
    }

    return;
}