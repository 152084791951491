import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Invoices = () => {
    return (
        <div className="container body__content">
            <h1 className="display-4">
                Facturas

                <Link to="new" className="text-primary">
                    <FontAwesomeIcon icon={faPlusCircle} className="icon__create" />
                </Link>
            </h1>

            { /** Show customers table */}
            <section style={{marginTop: 30}}>
                <p align="center">No hay información para mostrar</p>
            </section>
        </div>
    );
}

export default Invoices;