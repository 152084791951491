const Dashboard = () => {
    return (
        <div className="container body__content">
            <h1 className="display-4">
                Dashboard
            </h1>

            { /** Show customers table */}
            <section style={{marginTop: 30}}>
                <p align="center">No hay información para mostrar</p>
            </section>
        </div>
    );
}

export default Dashboard;