import './app/lib/styles/main.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

/** Libraries */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/** Single Modules */

import AdminModule from './app/modules';
import SignInPage from './app/core/pages/signIn';
import RecoverPage from './app/core/pages/recover';
import Footer from './app/core/templates/footer';

/** Module Components */
import CashFlowModule from './app/modules/cashflow';

/** Customers */
import CustomersModule from './app/modules/customers';
import CustomerInfoModule from './app/modules/customers/customer';
import NewCustomerModule from './app/modules/customers/new';
import EditCustomerModule from './app/modules/customers/edit';
import MainCustomerModule from './app/modules/customers/main';

import DashboardModule from './app/modules/dashboard';
import InvoicesModule from './app/modules/invoices';
import OrdersModule from './app/modules/orders';
import UsersModule from './app/modules/users';
import ProfileModule from './app/modules/users/profile';

function App() {
  return (
    <Router>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        
        <main style={{marginTop: 0}}>
          <Routes>
            { /* <Route path="/admin" element={<AdminModule />} /> */ }
            <Route path="/admin" element={<AdminModule />}>
                <Route path="" element={<DashboardModule />} />
                <Route path="orders" element={<OrdersModule />} />
                <Route path="invoices" element={<InvoicesModule />} />
                <Route path="cashflow" element={<CashFlowModule />} />

                <Route path="customers" element={<CustomersModule />}>
                  <Route path="" element={<MainCustomerModule />} />
                  <Route path=":user_id" element={<CustomerInfoModule />} />
                  <Route path=":user_id/edit" element={<EditCustomerModule />} />
                  <Route path="new" element={<NewCustomerModule />} />
                </Route>

                <Route path="users" element={<UsersModule />}>

                </Route>

                <Route path="profile" element={<ProfileModule />} />
            </Route>
            <Route path="/recover" element={<RecoverPage />} />
            <Route path="/" element={<SignInPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}


export default App;
