import { useParams } from "react-router-dom";

const Customer = () => {
    const { user_id } = useParams();

    return <div>
        Customer Summary {user_id}
    </div>
}

export default Customer;