import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as CustomerService from '../../lib/services/Customer.Service';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const Edit = () => {
    let navigate = useNavigate();
    const { user_id } = useParams();
    const [customerData, setCustomerData] = useState(0);

    useEffect(() => {
        const loadData = async () => {
            let result = await CustomerService.read(user_id);
            console.log('Result:',result);
            if(result) {
                setCustomerData(result);
            }
        }

        if(!customerData) {
            loadData();
        }
    }, [user_id, customerData]);

    const onDelete = async (e) => {
        e.preventDefault();
        let result  = await Swal.fire({
            title: 'Eliminar Cliente',
            icon: 'warning',
            html: 'Esta acción es irreversible y podrá eliminar toda la información que contiene este cliente, ¿Deseas continuar con la operación?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText:'Terminar'
        })

        console.log('Result:', result);
        if( result.isConfirmed ){
            result = await CustomerService.del(user_id);
            if(result) {
                navigate('/admin/customers');
            }
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        const form = document.getElementById('customer__edit_form').elements;
        const formData = {
            user: {
                name: form.name.value,
                lastname: form.lastname.value,
                email: form.email.value,
                phone: form.phone.value,
                tags: form.tags.value,
                notes: form.notes.value,
                updatedAt: new Date()
            },
            company: {
                address: form.address.value,
                address2: form.address2.value,
                state_id: form.state_id.value,
                province_id: form.province_id.value,
                zip: form.zip.value,
                rfc: form.rfc.value.toUpperCase(),
                name: form.company.value.toUpperCase()
            }
        }
        
        if(formData) {
            console.log('Submits:', formData);
            let result = await CustomerService.update(user_id, formData);
            if( result ) {
                toast.success(`Usuario ${formData.user.name + ' ' + formData.user.lastname} actualizado correctamente`);
                navigate('/admin/customers');
            }
        }
    }

    return (<div>
        { customerData && customerData.company ? 
        <div className="row">
            <div className="col-lg-6 offset-lg-3">
                <div className="card" style={{marginTop: 100}}>
                    <div className="card-header bg-dark text-white">
                        <div className="row">
                            <div className="col">
                                Actualizar Cliente
                            </div>
                            <div className="col-1" style={{textAlign:'right'}}>
                                <a href="/" className="text-danger" onClick={e => onDelete(e)}>
                                    <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                                </a>
                            </div>
                        </div>                        
                    </div>
                    <div className="card-body">
                        <form id="customer__edit_form" onSubmit={e => submit(e)}>
                            <legend>Información Básica *</legend>
                            <fieldset>
                                <div className="mb-3">
                                    <input type="text" name="name" className="form-control" placeholder="Nombre" defaultValue={customerData.name} required />
                                </div>
                                <div className="mb-3">
                                    <input type="text" name="lastname" className="form-control" placeholder="Apellidos" defaultValue={customerData.lastname} required />
                                </div>
                                <div className="mb-3">
                                    <input type="email" name="email" className="form-control" placeholder="Email" defaultValue={customerData.email} required />
                                </div>
                                <div className="mb-3">
                                    <input type="digits" name="phone" className="form-control" placeholder="Teléfono" defaultValue={customerData.phone} minLength={10} maxLength={10} required />
                                </div>
                            </fieldset>

                            <legend>Dirección Cliente/Empresa</legend>
                            <fieldset>
                                <div className="mb-3">
                                <input type="text" name="address" className="form-control" placeholder="Dirección" defaultValue={customerData.company.address} minLength={1} maxLength={150} />                                    
                                </div>
                                <div className="mb-3">
                                    <input type="text" name="address2" className="form-control" placeholder="Dirección 2" defaultValue={customerData.company.address2} minLength={1} maxLength={150} />
                                </div>
                                <div className="mb-3">
                                    <select name="state_id" className="form-control" defaultValue={customerData.company.state_id}>
                                        <option value="">Selecciona un Estado</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <select name="province_id" className="form-control" defaultValue={customerData.company.province_id}>
                                        <option value="">Selecciona un Municipio</option>
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <input type="digits" name="zip" className="form-control" placeholder="Código Postal" defaultValue={customerData.company.zip} minLength={5} maxLength={5} />
                                </div>
                            </fieldset>

                            <legend>Información Fiscal</legend>
                            <fieldset>
                                <div className="mb-3">
                                    <input type="text" name="rfc" className="form-control" placeholder="RFC" defaultValue={customerData.company.rfc} minLength={13} maxLength={13} />
                                </div>
                                <div className="mb-3">
                                    <input type="text" name="company" className="form-control" placeholder="Razón Social" defaultValue={customerData.company.name} minLength={1} maxLength={150} />
                                </div>
                            </fieldset>

                            <legend>Información Adicional</legend>
                            <fieldset>
                                <div className="mb-3">
                                    <textarea name="tags" className="form-control" defaultValue={customerData.tags} placeholder="Etiquetas (ej. Cliente Premium, Cliente Oro, Cliente Potencial...) separados por comas" />
                                </div>
                                <div className="mb-3">
                                    <textarea name="notes" className="form-control" defaultValue={customerData.notes} placeholder="Notas o Comentarios" />
                                </div>
                            </fieldset>

                            <div className="mb-3">
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-outline-primary">Actualizar Cliente</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        : '' }
    </div>);
}

export default Edit;