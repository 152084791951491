const Footer = () => {
    return (<footer style={{marginTop: 120}}>
        <div className="row">
            <div className="col-4 offset-4" style={{textAlign:'center'}}>
                <hr />
                <span style={{fontWeight:'bold'}}>Ver 1.0.0</span><br />
                <span className="text-secondary">Derechos Reservados &copy; 2021</span>
            </div>
        </div>
    </footer>)
}

export default Footer;